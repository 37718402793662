import { ref, watch, computed, onMounted } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

//firebase
import firebase from "@/utils/firebaseInit"
import firebaseTimeStamp from "firebase"
import {dbCollections} from "@/utils/firebaseCollection"
const db = firebase.firestore()
const months=["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"]
import { getCollectionDataTypesense, typesenseFetchAll } from '@/utils/Typesense/queries'
export default function useUsersList() {
  // Use toast
  const toast = useToast()

  const refUserListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    // { key: 'no', sortable: false },
    { key: 'unique_id', sortable: true },
    { key: 'date_of_enrolment', sortable: true },
    { key: 'student_name', sortable: true },
    { key: 'college_name',label: 'School/College Name',sortable: false },
    { key: 'education', sortable: false ,label: 'Degree'},
    { key: 'branchfiedlstream', sortable: false,label: 'Branch/Field/Stream'},
    // { key: 'amount',label: 'Amount (₹)', sortable: true },
    { key: 'status', sortable: true },
    { key: 'representative', sortable: true },
    { key: 'action', sortable: false },
    // { key: 'email', sortable: false },
    // { key: 'role', sortable: false },
    // {
    //   key: 'currentPlan',
    //   label: 'Plan',
    //   formatter: title,
    //   sortable: true,
    // },
    // { key: 'status', sortable: true },
  ]
  const perPage = ref(10)
  const totalUsers = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('')
  const isSortDirDesc = ref(null)
  const roleFilter = ref(null)
  const planFilter = ref(null)
  const statusFilter = ref(null)
  const representativeOptions = ref(null)
  const college = ref(null)
  const sortContect  = ref(null)
  const timeFnction  = ref(null)
  const students  = ref([])
  const employees  = ref([])

  const isSpinner = ref(null)

  //Select Filters
  const intake = ref(null)
  const yearFilter = ref(null)
  const representative = ref(null)
  const status = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refUserListTable.value ? refUserListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalUsers.value,
    }
  })

  const refetchData = () => {
    refUserListTable.value.refresh()
  }

  
  watch([currentPage, perPage, searchQuery, roleFilter, planFilter, intake, yearFilter, representative, college, status], () => {
    refetchData()
  })

  onMounted(()=>{
    getEmployeeDataFromTypesense().then(()=>{
      fetchStudentsFromTypesense(true,()=>refetchData());
      isSpinner.value = false;
    })
    // fetchStudents()
  })
  watch([perPage,intake,yearFilter,representative,status],()=>{
    fetchStudentsFromTypesense(true,()=>refetchData());
  })

  
  var usersList = []
  var repOptions=[{value:null,text:'All'}]
  var represent;
  isSpinner.value=true

  function handleSortChange(context) {
      let sortFieldObject = {
          unique_id : "createdAt",
          date_of_enrolment : "createdAt",
          student_name : "name",
          college_name : "",
          education : "", 
          branchfiedlstream : "", 
          status : "status.status", 
          representative : "representative.name",
          action : "", 
      }
      const {sortBy,sortDesc} = context
      sortContect.value = {sortBy:sortFieldObject[sortBy],sortDesc};
      fetchStudentsFromTypesense(false,()=>{refetchData()})
  }
  function handleSearch() {
    clearTimeout(timeFnction.value);
    timeFnction.value = setTimeout(() => {
      fetchStudentsFromTypesense(false,()=>{refetchData()});
    }, 1000);
  }
  async function handlePageChange(newPage) {
      currentPage.value = newPage;
      await fetchStudentsFromTypesense(false,()=>{refetchData()})
  }
  function getEmployeeDataFromTypesense() {
    return new Promise((resolve,reject)=>{
      try {
        let searchParameters = {
            collectionName: `${dbCollections.USERS}`,
            search: {
                'q'            : '*',
                'filter_by' : `isDeleted:!=true && role:='employee'`,
                'per_page': 250
            }
        }

        getCollectionDataTypesense(searchParameters).then((result)=>{
            typesenseFetchAll(dbCollections.USERS,result.hits,result.found,searchParameters.search,false,(groupResult)=>{
                let employeeData = [];
                if(groupResult.finalArray.length) {
                  groupResult.finalArray.map(e=>e.document).forEach(doc => {
                      employeeData.push(doc);
                      employees.value.push(doc);
                      repOptions.push({ value: { name: doc.name, eid: doc.employeeId, id: doc.id }, text: doc.name });
                  });
                  representativeOptions.value = repOptions;  
                  resolve(employeeData);
                } else {
                  resolve(employeeData);
                }
            })
        }).catch((error)=>{
          reject(error)
          console.error(error)
        });
      } catch (error) {
        console.error(error);
      }
    })
  }
  function fetchStudentsFromTypesense(resetResult = true,callback){
      try {
          if (resetResult) {
              currentPage.value = 1;
              totalUsers.value = 0;
          }
          let representativeArray = [];
          let employeeArry = [];
          employees.value.forEach(doc=>{
            if(!doc.isDeleted && doc.role == 'employee') {
              if(doc.representative){
                employeeArry.push({id:doc.id,rId:doc.representative.id})
              } else {
                employeeArry.push({id:doc.id})
              }
            }
          })
          function findRelatedRIds(id) {
              const relatedRIds = [];
              relatedRIds.push(id)
              const visitedIds = new Set();

              function traverseRelations(currentId) {
                  visitedIds.add(currentId);
                  
                  for (const relation of employeeArry) {
                      if (relation.rId === currentId && !visitedIds.has(relation.id)) {
                          relatedRIds.push(relation.id);
                          traverseRelations(relation.id);
                      }
                  }
              }
              
              traverseRelations(id);
              
              return relatedRIds;
          }
          let id = firebase.auth()?.currentUser?.uid ? firebase.auth()?.currentUser?.uid : JSON.parse(localStorage.getItem('currentUser')).id;
          representativeArray = findRelatedRIds(id)


          let searchParameters = {
              collectionName: `${dbCollections.USERS}`,
              search: {
                  q:"*",
                  page: currentPage.value,
                  per_page: perPage.value,
              }
          }

          let filter = `isDeleted:!=true && role:='student' && representative.id:[${representativeArray}]`

          if (intake.value !== null && intake.value !== '') {
              if(filter !== '') {
                  filter += ` && `
              }
              filter += `intake:=${intake.value}`
          }
          if (yearFilter.value !== null && yearFilter.value !== '') {
              if(filter !== '') {
                  filter += ` && `
              }
              filter += `year:=${yearFilter.value}`
          }
          if (representative.value !== null && representative.value !== '') {
              if(filter !== '') {
                  filter += ` && `
              }
              filter += `representative.id:=${representative.value.id ? representative.value.id : ''}`
          }
          if (status.value !== null && status.value !== '') {
              if(filter !== '') {
                  filter += ` && `
              }
              filter += `status.status:=${status.value}`
          }

          if(filter !== '' && filter !== null) {
            searchParameters.search.filter_by = filter;
          }
          if (sortContect.value) {
              searchParameters.search.sort_by = `${(sortContect.value.sortBy && sortContect.value.sortBy !== '') ? `${sortContect.value.sortBy}: ${sortContect.value.sortDesc == false ? 'asc' : 'desc'}` : ''}`;
          }
          if(searchQuery.value !== '' && searchQuery.value !== null) {
            searchParameters.search.q = searchQuery.value;
            searchParameters.search.query_by = 'name,representative.name,uniqueId,status.status';
          }

          getCollectionDataTypesense(searchParameters).then(async(result)=>{
              if(result.hits.length) {
                  totalUsers.value = result.found
                  const arrayData = await Promise.all(result.hits.map(async (x) => {
                    const studentObject = x.document;
                    let latestYear = 0;
                    let latestEduData = {};
                
                    studentObject.educationDetails.forEach(ed => {
                        if (ed.yearOfPassing >= latestYear) {
                            latestYear = ed.yearOfPassing;
                            latestEduData = ed;
                        }
                    });
                
                    const updateName = async (ele) => {
                        try {
                            let employeeIndex = employees.value.findIndex((e) => e.id === ele);
                            if (employeeIndex !== -1) {
                                let name = employees.value[employeeIndex]?.name || '';
                                return name;
                            } else {
                                let name = '';
                                if (ele) {
                                    const employeeData = await db.collection(dbCollections.USERS).doc(ele).get();
                                    name = employeeData.data()?.name || '';
                                    return name;
                                } else {
                                    return name;
                                }
                            }
                        } catch (error) {
                            throw error;
                        }
                    };
                
                    let employeeName = await updateName(studentObject.representative.id)
                
                    return {
                        id: studentObject.id,
                        status: studentObject.status,
                        unique_id: studentObject.uniqueId,
                        representative: employeeName,
                        repreID: studentObject.representative.id,
                        intake: studentObject.intake,
                        year: studentObject.year,
                        date_of_enrolment: `${new Date(studentObject.createdAt * 1000).getDate()} ${months[new Date(studentObject.createdAt * 1000).getMonth()]}, ${new Date(studentObject.createdAt * 1000).getFullYear()}`,
                        education: latestEduData.education || '',
                        branchfiedlstream: latestEduData.branchOrStreamOrFieldName || '-',
                        college_name: latestEduData.schoolOrCollegeName || '',
                        isDeleted: studentObject.isDeleted,
                        student_name: studentObject.name,
                        phone_no: '+91 ' + studentObject.mobileNumber,
                        createdInSeconds: studentObject.createdAt
                    };
                }));
                
                students.value = arrayData;
                callback(arrayData);

              } else {
                students.value = []
                totalUsers.value = 0;
                callback([]);
              }
          })
      } catch (error) {
          console.error(error)
          callback([]);
      }
  }
  const fetchUsers = (ctx, callback) => {
    var users = []
    if(usersList.length==0 && isSpinner.value)
    {
      db
        .collection(dbCollections.USERS)
        .orderBy('createdAt')
        .get()
        .then(querySnapshot=>{
          if(!querySnapshot.empty)
          {
            var startIndex = (currentPage.value-1)*perPage.value+1
            var endIndex = startIndex+perPage.value-1
            var index = 1
            var dataIndex = 1
            let representativeArray = [];
            let employeeArry = [];
            querySnapshot.forEach(doc=>{
              if(!doc.data().isDeleted && doc.data().role == 'employee') {
                if(doc.data().representative){
                  employeeArry.push({id:doc.data().id,rId:doc.data().representative.id})
                } else {
                  employeeArry.push({id:doc.data().id})
                }
                // if(doc.exists) {
                //   if(doc.data().id == firebase.auth().currentUser.uid){
                //     represent = doc.data()
                //   }
                //   // repOptions.push({value:{name:doc.data().name,eid:doc.data().employeeId,id:doc.data().id},text:doc.data().name})
                // }
              }
            })
            function findRelatedRIds(id) {
                const relatedRIds = [];
                relatedRIds.push(id)
                const visitedIds = new Set();

                function traverseRelations(currentId) {
                    visitedIds.add(currentId);
                    
                    for (const relation of employeeArry) {
                        if (relation.rId === currentId && !visitedIds.has(relation.id)) {
                            relatedRIds.push(relation.id);
                            traverseRelations(relation.id);
                        }
                    }
                }
                
                traverseRelations(id);
                
                return relatedRIds;
            }
            let id = firebase.auth().currentUser.uid
            representativeArray = findRelatedRIds(id)
            // employeeArry.forEach((item)=>{
            //   if(representativeArray.length === 0) {
            //     if(item.id == firebase.auth().currentUser.uid){
            //       representativeArray.push(firebase.auth().currentUser.uid)
            //     }
            //     if(item.rId){
            //       if(item.rId == firebase.auth().currentUser.uid){
            //         representativeArray.push(item.id)
            //       }
            //     }
            //   } else {
            //     if(item.rId){
            //       if(representativeArray.includes(item.rId)){
            //         representativeArray.push(item.id)
            //       }
            //     }
            //   }
            // })
            querySnapshot.docs.filter((x) => x.data().role === "employee")
            .forEach((doc) => {
              if(doc.exists && !doc.data().isDeleted && representativeArray.includes(doc.data().id)) {
                repOptions.push({value:{name:doc.data().name,eid:doc.data().employeeId,id:doc.data().id},text:doc.data().name})
              }
            })
            querySnapshot.forEach(doc=>{

              //Turn isDeleted Flag = false
              // db
              //   .collection(dbCollections.USERS)
              //   .doc(doc.id)
              //   .update({
              //     isDeleted:false
              //   })
              //   .catch(error => console.log(error))

              if(!doc.data().isDeleted && doc.data().role == 'student' && representativeArray.includes(doc.data().representative.id))
              {
                usersList.push(doc.data())

                var year = 0
                var eduData = ''

                doc.data().educationDetails.forEach(ed=>{
                  if(ed.yearOfPassing>=year)
                  {
                    year = ed.yearOfPassing
                    eduData=ed
                  }
                })

                usersList[usersList.length-1].unique_id=doc.data().uniqueId
                usersList[usersList.length-1].date_of_enrolment=`${new Date(doc.data().createdAt.seconds*1000).getDate()} ${months[new Date(doc.data().createdAt.seconds*1000).getMonth()]} ${new Date(doc.data().createdAt.seconds*1000).getFullYear()}`

                usersList[usersList.length-1].education=eduData.education
                usersList[usersList.length-1].college_name=eduData.schoolOrCollegeName
                usersList[usersList.length-1].isDeleted=doc.data().isDeleted||false
                usersList[usersList.length-1].student_name=doc.data().name
                usersList[usersList.length-1].phone_no='+91 '+doc.data().mobileNumber
              }
              
              if(dataIndex>=startIndex && dataIndex<=endIndex && !doc.data().isDeleted && doc.data().role == 'student' && representativeArray.includes(doc.data().representative.id))
              {
                // var amnt = 0
                // doc.data().payments.forEach(data=>{
                //   amnt+=parseFloat(data.rupees)
                // })
                var year = 0
                var eduData = ''

                doc.data().educationDetails.forEach(ed=>{
                  if(ed.yearOfPassing>=year)
                  {
                    year = ed.yearOfPassing
                    eduData=ed
                  }
                })
                users.push({
                  'id':doc.data().id,

                  'status':doc.data().status,
                  'unique_id':doc.data().uniqueId,
                  'representative':repOptions.filter(x=>x.value && x.value.id==doc.data().representative.id)[0].text || '--',
                  'intake':doc.data().intake,
                  'year':doc.data().year,
                  'date_of_enrolment':`${new Date(doc.data().createdAt.seconds*1000).getDate()} ${months[new Date(doc.data().createdAt.seconds*1000).getMonth()]}, ${new Date(doc.data().createdAt.seconds*1000).getFullYear()}`,

                  'education':eduData.education,
                  'branchfiedlstream' : eduData.branchOrStreamOrFieldName ? eduData.branchOrStreamOrFieldName : '-',
                  'college_name':eduData.schoolOrCollegeName,
                  // 'amount':(amnt).toFixed(2),
                  'isDeleted':doc.data().isDeleted||false,
                  'student_name':doc.data().name,
                  'phone_no':'+91 '+doc.data().mobileNumber,
                })
                dataIndex++;
              }
              if(index==querySnapshot.size)
              {
                totalUsers.value = usersList.length
                representativeOptions.value=repOptions

                isSpinner.value=false
                callback(users)
              }
              index++;
            })
          }
          else
          {
            totalUsers.value = querySnapshot.size
            isSpinner.value=false
            callback(users)
          }
        })
        .catch(error => {
          isSpinner.value=false
          console.log(error)
        })
    }
    else
    {
      var startIndex = (currentPage.value-1)*perPage.value+1
      var endIndex = startIndex+perPage.value-1
      var index = 1
      // console.log(startIndex,endIndex,searchQuery.value)
      if(usersList.length==0)
      {
        totalUsers.value = usersList.length
        isSpinner.value=false
        callback(users)
        return;
      }

      if(isSortDirDesc.value==false)
      {
        if(sortBy.value=='status')
        {
          usersList.sort((a, b) => (a.status.status > b.status.status ? 1 : -1))//Asc
        }
        else if(sortBy.value=='representative')
        {
          usersList.sort((a, b) => (a.representative.name > b.representative.name ? 1 : -1))//Asc
        }
        else
        {
          usersList.sort((a, b) => (a[sortBy.value] > b[sortBy.value] ? 1 : -1))//Asc
        }
      }
      else if(isSortDirDesc.value==true)
      {
        if(sortBy.value=='status')
        {
          usersList.sort((a, b) => (a.status.status > b.status.status ? -1 : 1))//Desc
        }
        else if(sortBy.value=='representative')
        {
          usersList.sort((a, b) => (a.representative.name > b.representative.name ? -1 : 1))//Desc
        }
        else
        {
          usersList.sort((a, b) => (a[sortBy.value] > b[sortBy.value] ? -1 : 1))//Desc
        }
      }
      
      usersList.forEach(data=>{
        if(searchQuery.value=='' && !representative.value && !status.value && !intake.value && !yearFilter.value && !college.value)
        {
          if(index>=startIndex && index<=endIndex && !data.isDeleted && data.role == 'student')
          {
            // var amnt = 0
            // data.payments.forEach(data2=>{
            //   amnt+=parseFloat(data2.rupees)
            // })
            var year = 0
            var eduData = ''

            data.educationDetails.forEach(ed=>{
              if(ed.yearOfPassing>=year)
              {
                year = ed.yearOfPassing
                eduData=ed
              }
            })
            users.push({
              'id':data.id,

              'status':data.status,
              'intake':data.intake,
              'year':data.year,
              'unique_id':data.uniqueId,
              'representative':repOptions.filter(x=>x.value && x.value.id==data.representative.id)[0].text || '--',
              'date_of_enrolment':`${new Date(data.createdAt.seconds*1000).getDate()} ${months[new Date(data.createdAt.seconds*1000).getMonth()]}, ${new Date(data.createdAt.seconds*1000).getFullYear()}`,

              'education':eduData.education,
              'branchfiedlstream' : eduData.branchOrStreamOrFieldName ? eduData.branchOrStreamOrFieldName : '-',
              'college_name':eduData.schoolOrCollegeName,
              // 'amount':(amnt).toFixed(2),
              'isDeleted':data.isDeleted||false,
              'student_name':data.name,
              'phone_no':'+91 '+data.mobileNumber,
            })
            // index++
          }
          if(index==usersList.length)
          {
            // if(currentPage.value > 1 && users.length==0)
            // {
            //   currentPage.value -= currentPage.value
            // }
            totalUsers.value = usersList.length
            isSpinner.value=false
            callback(users)
          }
          index++;
        }
        else if(!data.isDeleted && data.role == 'student')
        {
          // if(searchQuery.value!='')
          // {
            // var amnt = 0
            // data.payments.forEach(data2=>{
            //   amnt+=parseFloat(data2.rupees)
            // })
            var year = 0
            var eduData = ''

            data.educationDetails.forEach(ed=>{
              if(ed.yearOfPassing>=year)
              {
                year = ed.yearOfPassing
                eduData=ed
              }
            })

            users.push({
              'id':data.id,

              'status':data.status,
              'intake':data.intake,
              'year':data.year,
              'unique_id':data.uniqueId,
              'representative':repOptions.filter(x=>x.value && x.value.id==data.representative.id)[0].text || '--',
              'date_of_enrolment':`${new Date(data.createdAt.seconds*1000).getDate()} ${months[new Date(data.createdAt.seconds*1000).getMonth()]}, ${new Date(data.createdAt.seconds*1000).getFullYear()}`,

              'education':eduData.education,
              'branchfiedlstream' : eduData.branchOrStreamOrFieldName ? eduData.branchOrStreamOrFieldName : '-',
              'college_name':eduData.schoolOrCollegeName,

              // 'amount':(amnt).toFixed(2),
              'isDeleted':data.isDeleted||false,
              'student_name':data.name,
              'phone_no':'+91 '+data.mobileNumber,
            })

            if(index==usersList.length)
            {
              var finalArr = users;
              var tmp = [];

              var dataIndex = 1
              

              //Search Filter
              if(searchQuery.value!='')
              {
                finalArr.forEach(UD=>{
                  if(
                    searchQuery.value && UD.student_name.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
                    searchQuery.value && UD.college_name.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
                    searchQuery.value && UD.education.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
                    searchQuery.value && UD.unique_id.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
                    searchQuery.value && UD.representative && UD.representative.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
                    searchQuery.value && UD.status.status.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
                    searchQuery.value && UD.date_of_enrolment.includes(searchQuery.value)
                  )
                  {
                    tmp.push(UD)
                  }
                })
                finalArr=tmp
                tmp=[]
              }
              //CollegeFilter
              if(college.value!=null)
              {
                finalArr.forEach(UD=>{
                  if(UD.college_name == college.value && UD.college_name!='--')
                  { 
                    tmp.push(UD)
                  }
                })
                finalArr=tmp
                tmp=[]
              }
              //Status Filter
              if(status.value!=null)
              {
                finalArr.forEach(UD=>{
                  if(UD.status.status == status.value)
                  { 
                    tmp.push(UD)
                  }
                })
                finalArr=tmp
                tmp=[]
              }
              //Intake Filter
              if(intake.value!=null)
              {
                finalArr.forEach(UD=>{
                  if(UD.intake == intake.value)
                  { 
                    tmp.push(UD)
                  }
                })
                finalArr=tmp
                tmp=[]
              }
              //Year Filter
              if(yearFilter.value!=null)
              {
                finalArr.forEach(UD=>{
                  if(UD.year == yearFilter.value)
                  { 
                    tmp.push(UD)
                  }
                })
                finalArr=tmp
                tmp=[]
              }
              //Representative Filter
              if(representative.value!=null)
              {
                finalArr.forEach(UD=>{
                  if(UD.representative == representative.value.name)
                  { 
                    tmp.push(UD)
                  }
                })
                finalArr=tmp
                tmp=[]
              }

              //Final Table Data
              finalArr.forEach(FD=>{
                if(dataIndex>=startIndex && dataIndex<=endIndex && !FD.isDeleted)
                {
                  tmp.push(FD)
                }
                dataIndex++
              })
              
              totalUsers.value = finalArr.length
              isSpinner.value=false
              callback(tmp)
            }
            index++;
          // }
        }
        else
        {
          isSpinner.value=false
        }
      })
    }

    // for (let index = 0; index < 10; index++) {
    //   users.push({
    //     'No':index,
    //     'Date of Inquiry':`Date ${index}`,
    //     'Student Name':`Name ${index}`,
    //     'Phone no.':9462489464654,
    //     'College Name.':`Test`,
    //     'Branch.':`abcd`,
    //     'CGPA(%).':(index+1*(Math.random()*100)%100).toFixed(2),
    //     'actions':`<b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="btn-icon" > Create Account </b-button> <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="btn-icon" > Create New Inquiry </b-button>`
    //   })
    // }
    // callback(users)
  }

  const deleteStudent = (selectedStud,cb)=>{
    var index = (currentPage.value-1) * perPage.value + selectedStud.index + 1

    db
      .collection(dbCollections.USERS)
      .doc(selectedStud.item.id)
      .update({
        updatedAt:firebaseTimeStamp.firestore.FieldValue.serverTimestamp(),
        isDeleted:true
      })
      .then(()=>{
        usersList.splice(index-1,1)
        refetchData()
        cb(true,'success')
      })
      .catch(error=>{
        console.log(error)
        cb(false,error.message)
      })
  }

  const resolveUserRoleVariant = role => {
    if (role === 'subscriber') return 'primary'
    if (role === 'author') return 'warning'
    if (role === 'maintainer') return 'success'
    if (role === 'editor') return 'info'
    if (role === 'admin') return 'danger'
    return 'primary'
  }

  const resolveUserRoleIcon = role => {
    if (role === 'subscriber') return 'UserIcon'
    if (role === 'author') return 'SettingsIcon'
    if (role === 'maintainer') return 'DatabaseIcon'
    if (role === 'editor') return 'Edit2Icon'
    if (role === 'admin') return 'ServerIcon'
    return 'UserIcon'
  }

  const resolveUserStatusVariant = status => {
    if (status === 'pending') return 'warning'
    if (status === 'active') return 'success'
    if (status === 'inactive') return 'secondary'
    return 'primary'
  }

  return {
    fetchUsers,
    tableColumns,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refUserListTable,
    representativeOptions,
    // closeInquiry,

    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserStatusVariant,
    refetchData,

    isSpinner,

    deleteStudent,

    // Extra Filters
    roleFilter,
    planFilter,
    statusFilter,
    intake,
    yearFilter,
    representative,
    status,
    college,
    handlePageChange,
    handleSortChange,
    handleSearch,
    students
  }
}
