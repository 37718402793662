<template>
  <div>
    <b-spinner variant="primary" v-if="isSpinner" large class="pageLoadSpinners"/>
    <div v-show="!isSpinner">


      <!-- Filters -->
      <!-- <users-list-filters
        :role-filter.sync="roleFilter"
        :plan-filter.sync="planFilter"
        :status-filter.sync="statusFilter"
        :role-options="roleOptions"
        :plan-options="planOptions"
        :status-options="statusOptions"
      /> -->

      <!-- Filters -->
      <!-- <b-card
        title="Filters"
        v-show="false"
      > -->
      <b-card
        title="Filters"
      >
        <b-row class="tableFilters">
          <!-- <b-col md="2">
            <b-form-group label="College Name">
              <b-form-select
                v-model="college"
                :options="collegeOptions"
                placeholder="All"
              />
            </b-form-group>
          </b-col> -->
          <b-col md="2">
            <b-form-group label="Intake">
              <b-form-select
                v-model="intake"
                :options="intakeOptions"
                placeholder="All"
              />
            </b-form-group>
          </b-col>
          <b-col md="2">
            <b-form-group label="Year">
              <b-form-select
                v-model="yearFilter"
                :options="yearOptions"
                placeholder="All"
              />
            </b-form-group>
          </b-col>
          <b-col md="2">
            <b-form-group label="Representative">
              <b-form-select
                  v-model="representative"
                  :options="representativeOptions"
                  placeholder="Select"
              />
            </b-form-group>
          </b-col>
          <b-col md="2">
            <b-form-group label="Status">
              <b-form-select
                  v-model="status"
                  :options="statusOptions"
                  placeholder="Select"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-card>
      
      <!-- Table Container Card -->
      <b-card
        no-body
        class="mb-0"
      >

        <div class="m-2">

          <!-- Table Top -->
          <b-row>

            <!-- Search -->
            <b-col
              cols
              md="2"            
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <div>
                <b-form-input
                  v-model="searchQuery"
                  class="d-inline-block mr-1"
                  placeholder="Search..."
                  @input="handleSearch"
                />
                <!-- <b-button
                  variant="primary"
                  @click="isAddNewUserSidebarActive = true"
                >
                  <span class="text-nowrap">Add User</span>
                </b-button> -->
              </div>
            </b-col>
            <b-col
              cols
              md="4"
              class="d-flex align-items-center"
            >
              <div >
                <b style="color:#0D4EA0;font-size:16px">Total Students : {{totalUsers}}</b>
              </div>
            </b-col>

            <!-- Per Page -->
            <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-end mb-1 mb-md-0"
            >
              
              <v-select
                v-model="perPage"
                style="width: 90px"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
              />
              <!-- <label>entries</label> -->
            </b-col>
          </b-row>

        </div>

        <b-table
          ref="refUserListTable"
          class="position-relative"
          :items="students"
          responsive
          :fields="tableColumns"
          :current-page="1"
          no-local-sorting
          primary-key="id"
          :show-empty="!isSpinner"
          hover
          @row-clicked="handleRowClick"
          empty-text="No matching records found"
          :sort-by.sync="sortBy"
          :sort-desc.sync="isSortDirDesc"
          @sort-changed="handleSortChange"
        >
          <!-- Column: No. -->
          <template #cell(no)="data">
            <div>
              <span>{{((currentPage-1) * perPage + data.index + 1)>9?'':0}}{{(currentPage-1) * perPage + data.index + 1}}</span>
            </div>
          </template>

          <!-- Column: Eduaction -->
          <template #cell(education)="data">
            <div style="white-space: break-spaces;width: 250px;">
              {{data.item.education}}
            </div>
          </template>

          <!-- Column: Representative -->
          <template #cell(representative)="data">
            <div style="white-space: nowrap">
              {{data.item.representative||"--"}}
            </div>
          </template>

          <!-- Column: Status -->
          <template #cell(status)="data">
            <b-badge
              pill
              :variant="'light-'+data.value.variant"
            >
              <div style="white-space: nowrap">
                {{data.value.status}}
              </div>
            </b-badge>
          </template>

          <!-- Column: Action -->
          <template #cell(action)="data">
            <div style="white-space: nowrap" v-if="$route.meta.action=='write' || $route.meta.action==undefined">
              <a><img src='@/assets/images/erflog/Edit.png' class="action_icon" @click.prevent="$router.push({name:'edit-student',params:{name:data.item.student_name,id:data.item.id}})"/></a>
              <!-- <a><img src='@/assets/images/erflog/Delete.png' class="action_icon" @click.prevent="selectedUser=data,$bvModal.show('modal-delete-student')"/></a> -->
            </div>
          </template>

        </b-table>
        <div class="mx-2 mb-2">
          <b-row>

            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >

              <b-pagination
                v-model="currentPage"
                :total-rows="totalUsers"
                :per-page="perPage"
                 @change="handlePageChange"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>

            </b-col>

          </b-row>
        </div>
      </b-card>

      <!--Create Account Modal -->
      <b-modal
        id="modal-create-account"
        cancel-variant="outline-secondary"
        ok-title="Yes"
        cancel-title="No"
        centered
        title=""
        :no-close-on-backdrop="true"
        @hidden="selectedUser=''"
        @ok="createAccount"
      >
        <div style="text-align: center;" v-if="selectedUser!=''">
          <label class="s_modal_title">Create Account</label>
          <p>Are you sure you want to create account for <span>{{selectedUser.item.student_name}} ?</span></p>
        </div>
      </b-modal>

      <!--Close Inquiry Modal -->
      <b-modal
        id="modal-close-inquiry"
        cancel-variant="outline-secondary"
        ok-title="Yes"
        cancel-title="No"
        centered
        title=""
        :no-close-on-backdrop="true"
        @hidden="selectedUser=''"
        @ok="closeInquiry"
      >
        <div style="text-align: center;" v-if="selectedUser!=''">
          <label class="s_modal_title">Close Inquiry</label>
          <p>Are you sure you want to close this query for {{selectedUser.item.student_name}} ?</p>
        </div>
      </b-modal>
    </div>

    <!-- Delete Student -->
    <b-modal
      id="modal-delete-student"
      cancel-variant="outline-secondary"
      ok-title="Delete"
      ok-variant="danger"
      cancel-title="Cancel"
      centered
      title=""
      :no-close-on-backdrop="true"
      @hidden="selectedUser=''"
      @ok="deleteStudentFunction()"
    >
    <div class="create_account_img">
      <img src="@/assets/images/erflog/DeletePopup.png" alt="">
    </div>
      <div style="text-align: center;" v-if="selectedUser!=''">
        <label class="s_modal_title">Delete Student</label>
        <p>Are you sure you want to delete this student ?</p>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,BModal,BFormSelect,BFormGroup,BSpinner
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import useUsersList from './useStudentList'

import firebase from '@/utils/firebaseInit'
import { dbCollections } from '@/utils/firebaseCollection'
const db = firebase.firestore()

import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BModal,
    BFormSelect,
    BFormGroup,
    vSelect,
    BSpinner,
  },
  data(){
    return{
      selectedUser:"",
      // university:null,
      // representative:null,
      // status:null,

      collegeOptions:[
        { value: null, text: 'All' },
      ],
      statusOptions:[
        { value: null, text: 'All' },
        { value: 'Application Pending', text: 'Application Pending' },
        { value: 'Application Inprogress', text: 'Application Inprogress' },
        { value: 'Application Submitted', text: 'Application Submitted' },
        { value: 'Admission Successful', text: 'Admission Successful' },
        { value: 'Application Process Hold', text: 'Application Process Hold' }
      ],

      intakeOptions:[
        {value:null,text:'All'},
        'WS',
        'SS'
      ],
      yearOptions:[{value:null,text:'All'}]
    }
  },
  created() {
    var self = this

    //push years
    for (let i = 0; i <= 4; i++)
    {
      self.yearOptions.push(new Date().getFullYear()+i)
    }

    // db
    //   .collection(dbCollections.USERS)
    //   .orderBy('createdAt')
    //   .get()
    //   .then((querySnapshot)=>{
    //       querySnapshot.forEach(doc=>{
    //           if(!doc.data().isDeleted && doc.data().role == 'employee')
    //           {
    //               self.representativeOptions.push(doc.data().name)
    //           }
    //       })
    //   })
    //   .catch(error=>console.log(error))

    //Get Universities
    db
      .collection(dbCollections.COLLEGES)
      .orderBy('createdAt')
      .get()
      .then((querySnapshot)=>{
        self.collegeOptions = [{value:null,text:'All'}];
        querySnapshot.forEach(doc=>{
          if(!doc.data().isDeleted)
            self.collegeOptions.push(doc.data().collegeName)
        })
      })
      .catch(error=>{
        console.log(error)
      })
  },

  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-user'

    // Register module
    // if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const isAddNewUserSidebarActive = ref(false)

    const roleOptions = [
      { label: 'Admin', value: 'admin' },
      { label: 'Author', value: 'author' },
      { label: 'Editor', value: 'editor' },
      { label: 'Maintainer', value: 'maintainer' },
      { label: 'Subscriber', value: 'subscriber' },
    ]

    const planOptions = [
      { label: 'Basic', value: 'basic' },
      { label: 'Company', value: 'company' },
      { label: 'Enterprise', value: 'enterprise' },
      { label: 'Team', value: 'team' },
    ]

    const statusOptions = [
      { label: 'Pending', value: 'pending' },
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ]

    const {
      fetchUsers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      representativeOptions,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      isSpinner,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,

      deleteStudent,

      intake,
      yearFilter,
      representative,
      status,
      college,
      students,
      handlePageChange,
      handleSortChange,
      handleSearch
    } = useUsersList()

    return {

      // Sidebar
      isAddNewUserSidebarActive,

      fetchUsers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      representativeOptions,
      refetchData,

      isSpinner,

      // Filter
      avatarText,

      intake,
      yearFilter,
      representative,
      status,

      deleteStudent,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      roleOptions,
      planOptions,
      statusOptions,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
      college,
      students,
      handlePageChange,
      handleSortChange,
      handleSearch
    }
  },
  methods:{
    deleteStudentFunction()
    {
      var self = this

      var data = self.selectedUser

      self.$axios.post(dbCollections.APIURL+'removeUserFromAuth',{uid:data.item.id})
      .then((resp)=>{
        if(resp.data.status==200)
        {
          self.deleteStudent(data,(status,msg)=>{
            if(status)
            {
              self.$root.$emit('showToastMessage','Student deleted successfully.','success')
            }
            else
            {
              self.$root.$emit('showToastMessage',msg,'danger')
            }
          })
        }
        else
        {
          console.log(resp.data.msg)
          self.$root.$emit('showToastMessage',resp.data.msg,'danger')
        }
      })
      .catch(error=>{
        self.$root.$emit('showToastMessage',error.message,'danger')
        console.log(error)
      })
    },
    handleRowClick(data)
    {
      // console.log(data)
      //Show User Details
      this.$router.push({name:'student-information',params:{name:data.student_name,id:data.id}})
    },
    createAccount(e)
    {
      var index = (this.currentPage-1) * this.perPage + this.selectedUser.index + 1
      console.log("createAccount",this.selectedUser,index)
      this.selectedUser=''
    },
    closeInquiry(e)
    {
      var index = (this.currentPage-1) * this.perPage + this.selectedUser.index + 1
      console.log("closeInquiry",this.selectedUser,index)
      db
        .collection(dbCollections.INQUIRY)
        .doc(this.selectedUser.item.id)
        .delete()
        .then(()=>{
          console.log("Inquiry closed successfully.")
          this.selectedUser=''
        })
        .catch(error=>{
          console.log(error)
        })
    }
  }
}
</script>
<style scoped>
.card .card-body h4.card-title{
  border-bottom:none !important;
  margin: 0px -20px 0px -14px !important;
}
.tableFilters .form-group{
  margin-bottom: 0px !important;
}
</style>
<style>
.position-relative.table-responsive thead tr th{
  white-space: nowrap !important;
}
/* .position-relative.table-responsive tbody tr td{
  white-space: nowrap !important;
} */


.s_modal_title{
    width: 100%;
    
    font-weight: 600;
    font-size: 20px;
}
</style>
<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
